import { HttpService } from "@snp/libraries/core";

export class Utils {
  public static getAgGridLicense(): string {
    return process.env.agGridLicenseKey as string;
  }

  public static getDocOwnerEntityName = (
    entityType: string,
    docEntityType: string,
    docOwnerEntityName: string
  ) => {
    if (docEntityType === "Account") {
      return docEntityType;
    } else if (docEntityType === "Project") {
      return "MarkitProject";
    } else if (docEntityType === "Unit") {
      return "Unit";
    } else {
      return null;
    }
  };

  public static getDefaultTopRow = (columns: Array<any>[], extraParams: any = null) => {
    const topRow: any = {};
    if (columns?.length > 0) {
      columns.forEach((col: any) => {
        topRow[col.field] = "";
      });
    }
    return {
      ...topRow,
      id: `${new Date().getTime()}-new`,
      ...extraParams
    };
  };

  public static genUniqueId = () => {
    return Math.random().toString(16).slice(2);
  }

  public static resolveComponent = async (component: any) => {
    const _component = await component._payload._result();
    return _component.default;
  }

  public static getFilenameFromHeader = (contentDisposition: string | null, defaultFilename = 'filename.pdf') => {
    let filename = defaultFilename;
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      let matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  public static numberFormatter = (value: number, defaultValue: string = '-') => {
    if (value && !isNaN(value)) {
      return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(value);
    } else {
      return defaultValue;
    }
  }

  public static downloadFile = (url: string, isPublic = false) => {
    HttpService.get(url, isPublic, null, true, true).subscribe(
        (res: any) => {
            const _blob = new Blob([res.blob], {
                type: "application/octet-stream",
            });
            if (_blob?.size) {
                const url = window.URL.createObjectURL(_blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = res.filename;
                a.click();
                URL.revokeObjectURL(url);
            }
        }
      );
  }

  public static replaceParam = (body: string, param: string, value: string) => {
    const iterLiteral = '\\{' + param + '\\}';
    const re = new RegExp(iterLiteral, 'g');
    return body.replace(re, value);
  };
  
  public static getAllParams = (body: string) => {
    return [...body.matchAll(/\{(.*?)}/g)].map(a => a[1]);
  };

}
